import request from '@/utils/request'


export function save(data) {
  return request({
    url: 'AppAccount/save',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: 'AppAccount/delete',
    method: 'post',
    data
  })
}

export function getPageList(data) {
  return request({
    url: 'AppAccount/getPageList',
    method: 'post',
    data
  })
}

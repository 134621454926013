<template>
  <div class="app-container">
    <section>
      <el-form :inline="true" class="filter">
        <el-form-item>
          <el-button size="small" type="success" @click="handleCreate"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table
        size="mini"
        stripe
        border
        :data="tableData"
        highlight-current-row
        expand-on-click-node="false"
        style="width: 100%"
           :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }"
      >
        <el-table-column prop="AppAccoutType" label="类型"  width="120">
          <template slot-scope="scope">
              {{ format.appAccountTypeFormat(scope.row.AppAccoutType) }}
          </template>
        </el-table-column>
        <el-table-column prop="AppName" label="应用名称" width="120">
        </el-table-column>
        <el-table-column prop="AppID" label="AppID" min-width="120">
        </el-table-column>
        <el-table-column prop="AppKey" label="AppKey" min-width="150">
        </el-table-column>
        <el-table-column
          prop="AppSecret"
          label="应用秘钥"
          min-width="160"
        >
        </el-table-column>
        <el-table-column prop="Token" label="Token" min-width="150">
        </el-table-column>
        <el-table-column
          prop="TokenDuration"
          label="Token有效时长"
          min-width="150"
        >
        </el-table-column>
        <el-table-column prop="Description" label="其他配置" width="150">
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleUpdate(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizesList" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page.sync="pageIndex"></el-pagination>
      </div>
      <!--新增界面-->
      <div v-if="formVisible">
        <el-dialog
          v-dragDialog
          :title="textMap[dialogStatus]"
          :visible.sync="formVisible"
          width="600px"
          :close-on-click-modal="false"
        >
          <el-form
            :model="addForm"
            ref="dataForm"
            label-width="120px"
            :rules="formRules"
          >
            <el-form-item label="类型:" prop="AppAccoutType">
              <el-col :span="19">
                <el-select
                  v-model="addForm.AppAccoutType"
                  placeholder="请选择类型"
                  value-key="Key"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in AppAccoutTypes"
                    :key="item.Key"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="应用名称:" prop="AppName">
              <el-col :span="19">
                <el-input
                  v-model="addForm.AppName"
                  auto-complete="off"
                  placeholder="请输入应用名称"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="应用ID:" prop="AppID">
              <el-col :span="19">
                <el-input
                  v-model="addForm.AppID"
                  auto-complete="off"
                  placeholder="请输入ID"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="AppKey：" prop="AppKey">
              <el-col :span="19">
                <el-input
                  v-model="addForm.AppKey"
                  auto-complete="off"
                  placeholder="请输入AppKey"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="AppSecret：" prop="AppSecret">
              <el-col :span="19">
                <el-input
                  v-model="addForm.AppSecret"
                  auto-complete="off"
                  placeholder="请输入AppSecret"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="Token:" prop="Token">
              <el-col :span="19">
                <el-input
                  v-model="addForm.Token"
                  auto-complete="off"
                  placeholder="请输入Token"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="Token有效时间:" prop="TokenDuration">
              <el-col :span="19">
                <el-input
                  v-model.number="addForm.TokenDuration"
                  auto-complete="off"
                ></el-input>
                <span>单位秒</span>
              </el-col>
            </el-form-item>
            <el-form-item label="其他配置：" prop="Description">
              <el-col :span="19">
                <el-input
                  v-model="addForm.Description"
                  type="textarea"
                  auto-complete="off"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">取消</el-button>
            <el-button
              v-if="dialogStatus == 'create'"
              :loading="submitLoading"
              type="primary"
              @click="createSubmit"
              >提交</el-button
            >
            <el-button
              v-else
              type="primary"
              :loading="submitLoading"
              @click="updateSubmit"
              >提交</el-button
            >
          </div>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { getPageList, save, del } from "@/api/setting/appAccount";
export default {
  data() {
    return {
      AppAccoutTypes: [
        { Key: "Other", Name: "其他", Value: 0 },
        { Key: "WXPay", Name: "微信支付", Value: 10 },
        { Key: "WXApp", Name: "微信小程序", Value: 20 },
        { Key: "WXPublicApp", Name: "微信公众号", Value: 30 },
      ],
      tableData: [],
      total:0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      dialogStatus: "",
      formVisible: false,
      textMap: {
        create: "创建",
        update: "修改",
      },
      addForm: {},
      formRules: {
        AppName: [
          { required: true, message: "请输入应用名称", trigger: "change" },
        ],
        AppID: [{ required: true, message: "请输入应用ID", trigger: "change" }],
        AppAccoutType: [
          { required: true, message: "请选择应用类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    queryAll() {
      let para = {pageIndex:this.pageIndex,pageSize:this.pageSize,search:""};
      getPageList(para).then((response) => {
        if (response) {
          this.tableData = response.data.Items;
          this.total = response.data.Total||response.data.Items.length;
        }
      });
    },
    handleCreate() {
      this.dialogStatus = "create";
      this.formVisible = true;
      this.addForm = {
        AppName: "",
        AppID: "",
        AppKey: "",
        AppSecret: "1",
        AppAccoutType: "",
        Token: "",
        TokenDuration: "",
        Description: "",
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },

    createSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let para = Object.assign(this.addForm);
          this.$confirm("确认提交吗？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
          }).then(() => {
            save(para).then((response) => {
              if (response) {
                this.formVisible = false;
                this.queryAll();
              }
            });
          });
        }
      });
    },

    handleUpdate(row) {
      this.addForm = JSON.parse(JSON.stringify(row));
      this.dialogStatus = "update";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },

    updateSubmit: function () {
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        let data = Object.assign({}, this.addForm);
        save(data).then((response) => {
          if (response) {
            this.formVisible = false;
            this.queryAll();
          }
        });
      });
    },
    //删除
    handleDelete: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { key: row.Key };
        del(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.queryAll();
          }
        });
      });
    },
       handleSizeChange (val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getUserList());
    },

    handleCurrentChange (val) {
      this.pageIndex = `${val}`;
      this.getUserList();
    },
  },
  mounted() {
    this.queryAll();
  },
};
</script>
